import {
  buildUrlPrefix,
  getCategoryRoute as getCoreCategoryRoute,
  getProductRoute as getCoreProductRoute,
} from '@shopware/helpers'
import { useUrlResolver } from '#imports'
import type { Schemas } from '#shopware'

export const useShopRouter = () => {
  const localePath = useLocalePath()
  const urlResolver = useUrlResolver()
  const urlPrefix = urlResolver.getUrlPrefix()

  const getCategoryRoute = (category: Schemas['Category']) => {
    const path = getCoreCategoryRoute(category)
    if (typeof path === 'string' && path.includes('undefined')) {
      return undefined
    }

    return localePath(buildUrlPrefix(path, urlPrefix).path)
  }

  const getProductRoute = (product: Schemas['Product']) => {
    return localePath(
      buildUrlPrefix(getCoreProductRoute(product), urlPrefix).path
    )
  }

  const getShopRoute = (route: string) => {
    return localePath(buildUrlPrefix(route, urlPrefix).path)
  }

  return {
    getCategoryRoute,
    getProductRoute,
    getShopRoute,
    urlPrefix,
  }
}
